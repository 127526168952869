export const ApiQueryKey = {
	admins: 'admins',
	users: 'users',
	crewman: 'crewman',
	pilots: 'pilots',

	locations: 'locations',
	startingPoints: 'starting-points',
	userVoucherPassengers: 'user-voucher-passengers',

	partners: 'partners',
	shippingMethods: 'shipping-methods',

	productCategories: 'product-categories',
	products: 'products',
	voucherCategories: 'voucher-categories',
	vouchers: 'vouchers',
	userVouchers: 'user-vouchers',
	order: 'order',
	orderItem: 'order-items',
	payment: 'payment',
	cart: 'cart',

	discountCodes: 'discount-codes',

	cylinders: 'cylinders',
	balloons: 'balloons',

	flights: 'flights',
	flightPassengers: 'flight-passengers',
	pilotExpenses: 'pilot-expenses',

	roles: 'roles',
	userLogs: 'user-logs',
	stats: 'stats',

	content: 'content',
	settings: 'settings',
} as const;

export type ApiQueryKey = (typeof ApiQueryKey)[keyof typeof ApiQueryKey];
