import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreKey } from '@/configs/storeKeys';
import { PilotExpenseMethod, PilotExpenseType } from '@/api/PilotExpenseApi';
import { UUID } from '@/types/types';
import type { RootState } from '@/store/index';

export type PilotExpenseRow = {
	isNew?: boolean;
	date: string | null;
	amount: number;
	method: PilotExpenseMethod;
	document: string;
	from: string;
	to: string;
	description: string;
	dietsAmount: number;
	flightsAmount: number;
	readinessAmount: number;
	dateFrom: string | null;
	dateTo: string | null;
	location: string;
	dayTime: string;
};

export type PilotExpensesForm = [
	Record<UUID, PilotExpenseRow>,
	Record<UUID, PilotExpenseRow>,
	Record<UUID, PilotExpenseRow>,
	Record<UUID, PilotExpenseRow>,
	Record<UUID, PilotExpenseRow>,
	Record<UUID, PilotExpenseRow>,
	Record<UUID, PilotExpenseRow>
];

interface SliceState {
	removedExpenses: UUID[];
	form: PilotExpensesForm;
}

const initialState: SliceState = {
	removedExpenses: [],
	form: getInitialExpsnesesForm(),
};

export const pilotExpensesSlice = createSlice({
	name: StoreKey.PILOT_EXPENSES,
	initialState,
	reducers: {
		loadExpenses: (state, action: PayloadAction<PilotExpensesForm>) => {
			state.form = action.payload;
		},
		addExpense: (state, action: PayloadAction<PilotExpenseType>) => {
			state.form[action.payload][crypto.randomUUID()] = {
				isNew: true,
				date: null,
				amount: 0,
				description: '',
				document: '',
				dietsAmount: 0,
				flightsAmount: 0,
				from: '',
				method: PilotExpenseMethod.CASH,
				readinessAmount: 0,
				to: '',
				dateFrom: null,
				dateTo: null,
				location: '',
				dayTime: '',
			};
		},
		removeExpense: (
			state,
			action: PayloadAction<{ type: PilotExpenseType; id: string }>
		) => {
			state.removedExpenses.push(action.payload.id as UUID);
			delete state.form[action.payload.type][action.payload.id as UUID];
		},
		handleExpsenseChange: (
			state,
			action: PayloadAction<{
				type: PilotExpenseType;
				id: UUID;
				key: keyof PilotExpenseRow;
				value: PilotExpenseRow[keyof PilotExpenseRow];
			}>
		) => {
			// @ts-ignore
			state.form[action.payload.type][action.payload.id][action.payload.key] =
				action.payload.value;
		},
		resetExpenseForm: (state) => {
			state.removedExpenses = [];
			state.form = getInitialExpsnesesForm();
		},
	},
});

export const {
	loadExpenses,
	addExpense,
	removeExpense,
	handleExpsenseChange,
	resetExpenseForm,
} = pilotExpensesSlice.actions;
export const selectPilotExpensesState = (state: RootState) =>
	state[StoreKey.PILOT_EXPENSES];
export default pilotExpensesSlice.reducer;

export function getInitialExpsnesesForm(): PilotExpensesForm {
	return [{}, {}, {}, {}, {}, {}, {}];
}
