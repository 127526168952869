import {
	useContext,
	ReactNode,
	useLayoutEffect,
	createContext,
	useState,
} from 'react';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { Admin, AppUser, Crewman, Pilot, UserType } from '@/types/users';
import { LoginValues, SubmitStatus } from '@/pages/unauthenticated/Login';
import { useQueryClient } from '@tanstack/react-query';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import APP_CONFIG from '@/configs/appConfig';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import httpClient from '@/api/httpClient';
import Cookies from 'js-cookie';

interface TokenPayload extends JwtPayload {
	user: AppUser;
}

interface Context {
	user: AppUser | null;
	changeUser: (newUser?: AppUser | null) => void;
	login: (values: LoginValues) => Promise<number>;
	logout: () => void;
	isAdmin: boolean;
	isPilot: boolean;
	isCrewman: boolean;
}

const AuthContext = createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const [user, setUser] = useLocalStorage<AppUser | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const isAdmin = !!user && isUserAdmin(user),
		isPilot = !!user && isUserPilot(user),
		isCrewman = !!user && isUserCrewman(user);

	const [sessionRefreshToken, setSessionRefreshToken, clearSessionToken] =
		useSessionStorage<string | undefined>({
			key: APP_CONFIG.SESSION_STORAGE_KEYS.REFRESH_TOKEN,
			defaultValue: Cookies.get(APP_CONFIG.SESSION_STORAGE_KEYS.REFRESH_TOKEN),
			getInitialValueInEffect: false,
		});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	useLayoutEffect(() => {
		loginWithResfreshToken(sessionRefreshToken);
	}, []);

	const loginWithResfreshToken = async (refresh?: string) => {
		if (!refresh) {
			logout();
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			const remember = !!Cookies.get(
				APP_CONFIG.SESSION_STORAGE_KEYS.REFRESH_TOKEN
			);

			setAuth(decoded, accessToken, refreshToken, remember);
		} catch (error: any) {
			console.error(error);
			if (error.message !== 'Request aborted') {
				setUser(null);
				eradicateRefreshToken();
			}
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload, refresh: string) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = setTimeout(() => {
			loginWithResfreshToken(refresh);
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const login: Context['login'] = async ({ email, password, remember }) => {
		try {
			const response = await UnauthenticatedApi.login({ email, password });

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			setAuth(decoded, accessToken, refreshToken, remember);

			return SubmitStatus.SUCCESS;
		} catch (error) {
			console.error(error);
			return SubmitStatus.ERROR;
		}
	};

	const logout: Context['logout'] = () => {
		queryClient.cancelQueries();
		queryClient.clear();
		eradicateRefreshToken();
		setUser(null);
		httpClient.defaults.headers.common['Authorization'] = '';
		clearTimeout(refreshTimeout);
	};

	const eradicateRefreshToken = () => {
		clearSessionToken();
		Cookies.remove(APP_CONFIG.SESSION_STORAGE_KEYS.REFRESH_TOKEN);
	};

	const saveRefreshToken = (refreshToken: string) => {
		setSessionRefreshToken(refreshToken);
		Cookies.set(APP_CONFIG.SESSION_STORAGE_KEYS.REFRESH_TOKEN, refreshToken, {
			secure: true,
			expires: 360,
			sameSite: 'lax',
		});
	};

	const setAuth = (
		decoded: TokenPayload,
		accessToken: string,
		refreshToken: string,
		remember = true
	) => {
		const { user } = decoded;

		if (
			![UserType.ADMIN, UserType.PILOT, UserType.CREWMAN].includes(
				user.userType
			)
		)
			throw new Error('User type not allowed!');

		setAuthHeader(accessToken);

		if (remember) saveRefreshToken(refreshToken);
		else setSessionRefreshToken(refreshToken);

		refreshTokenWhenExpire(decoded, refreshToken);

		setUser(user);
	};

	const changeUser: Context['changeUser'] = (newUser = null) =>
		setUser(newUser);

	return (
		<AuthContext.Provider
			value={{
				user,
				changeUser,
				login,
				logout,
				isAdmin,
				isPilot,
				isCrewman,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function isUserAdmin(user: AppUser): user is Admin {
	return user.userType === UserType.ADMIN;
}

function isUserPilot(user: AppUser): user is Pilot {
	return user.userType === UserType.PILOT;
}

function isUserCrewman(user: AppUser): user is Crewman {
	return user.userType === UserType.CREWMAN;
}
