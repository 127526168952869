import { ApiQueryKey } from '@/configs/apiQueryKey';
import { UUID } from '@/types/types';
import { Payment } from './PaymentApi';
import { PaymentMethod } from '@/configs/paymentOptions';
import { CartPrice } from './CartApi';
import { User } from '@/types/users';
import { ShippingMethod } from './ShippingMethodApi';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export enum OrderStatus {
	PENDING,
	ACCEPTED,
	REJECTED,
}

export interface OrderItem {
	id: UUID;
	createdAt: string;
	name: string;
	price: CartPrice;
	quantity: number;
	productId: UUID | null;
}

export interface Order {
	id: UUID;
	orderId: string;
	orderItems: OrderItem[];
	user: User;
	payments: Payment[];
	paymentMethod: PaymentMethod;
	shippingMethod: ShippingMethod;
	status: OrderStatus;
	price: CartPrice;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	city: string;
	address: string;
	postalCode: string;
	createdAt: string;
}

class OrderApi extends EntityApi<Order> {
	constructor() {
		super(ApiQueryKey.order);
		this.addOrderItem = this.addOrderItem.bind(this);
	}

	async addOrderItem({
		orderId,
		productId,
	}: {
		orderId: UUID;
		productId: UUID;
	}) {
		const response = await httpClient.patch<Order>(
			`${this.queryKey}/${orderId}/add-product`,
			{
				product: { id: productId },
				quantity: 1,
			}
		);

		return response.data;
	}

	async updateOrderItem({
		orderItemId,
		quantity,
	}: {
		orderItemId: UUID;
		quantity: number;
	}) {
		const response = await httpClient.patch<Order>(
			`${ApiQueryKey.orderItem}/${orderItemId}`,
			{
				quantity,
			}
		);

		return response.data;
	}
}

export default new OrderApi();
