import { UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { User } from '@/types/users';
import { UserVoucherPassenger } from './UserVoucherPassengerApi';
import { UserVoucher } from './UserVoucherApi';
import EntityApi, { BaseEntity } from './EntityApi';
import httpClient from './httpClient';

export enum FlightPassengerStatus {
	REGISTERED,
	ACCEPTED,
	REJECTED,
	RESIGNED,
	ACCEPTED_TO_ANOTHER_FLIGHT,
	SUSPENDED,
}

export interface FlightPassenger {
	id: UUID;
	userVouchers: UserVoucher[];
	passengers: UserVoucherPassenger[];
	user: User;
	status: FlightPassengerStatus;
}

class FlightPassengerApi extends EntityApi<FlightPassenger> {
	constructor() {
		super(ApiQueryKey.flightPassengers);
		this.bulkStatusChange = this.bulkStatusChange.bind(this);
	}

	async bulkStatusChange(
		payload: {
			flightPassenger: BaseEntity;
			status: FlightPassengerStatus;
		}[]
	) {
		await httpClient.post(`${this.queryKey}/bulk-update`, payload);

		return 1;
	}
}

export default new FlightPassengerApi();
