import { UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { Balloon } from './BalloonApi';
import { StartingPoint } from './StartingPointApi';
import { Crewman, Pilot } from '@/types/users';
import { Voucher } from './VoucherApi';
import { Location } from './LocationApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { CalculateExpensesForm } from '@/pages/pilotExpenses/partials/CalculateCostsModal';
import { FlightPassenger } from './FlightPassengerApi';
import { pick } from 'lodash';
import EntityApi, { BaseEntity } from './EntityApi';
import httpClient from './httpClient';

export enum FlightStatus {
	DISABLED,
	PLANNED,
	CANCELLED,
	FINISHED,
	ACCEPTED,
}

export enum PilotAcceptanceStatus {
	PENDING,
	ACCEPTED,
	REJECTED,
}

export interface Flight {
	id: UUID;
	balloon: Balloon;
	location: Location;
	startingPoint: StartingPoint;
	vouchers: Voucher[];
	status: FlightStatus;
	pilotAcceptanceStatus: PilotAcceptanceStatus;
	pilot: Pilot;
	crewmen: Crewman[];
	crewmanApplications: Crewman[];
	temperature: number;
	altitude: number;
	startDate: string;
	publicationDate: string;
	confirmationDate: string;
	createdAt: string;
	flightPassengers: FlightPassenger[];
	reserveFlight?: Flight;
	mainFlight?: Flight;
	currentPassengersWeight: number;
	maxPassengersWeight: number;
	cancelNote?: string;
}

class FlightApi extends EntityApi<Flight> {
	constructor() {
		super(ApiQueryKey.flights);
		this.crewmanApplication = this.crewmanApplication.bind(this);
		this.getPreviousLocation = this.getPreviousLocation.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.bulkStatusChange = this.bulkStatusChange.bind(this);
		this.generateCertificate = this.generateCertificate.bind(this);
	}

	async crewmanApplication(flightId: UUID) {
		const response = await httpClient.patch<Flight>(
			`${this.queryKey}/${flightId}/crewman-apply`
		);

		return response.data;
	}

	async calculateCosts({
		queryKey,
	}: QueryFunctionContext<[ApiQueryKey, UUID, CalculateExpensesForm]>) {
		const stops = queryKey[2].stops.filter((stop) => stop.length >= 3);

		const payload = pick(queryKey[2], ['fuelConsumption', 'fuelPrice']);

		const response = await Promise.all(
			[queryKey[2].origin, ...stops, queryKey[2].destination].map(
				(place, idx, arr) => {
					if (arr.length - 1 === idx)
						return Promise.resolve({
							data: {
								fuelCost: 0,
								duration: 0,
								distance: 0,
							},
						});

					const origin = place,
						destination = arr[idx + 1];

					return httpClient.post<{
						fuelCost: number;
						duration: number;
						distance: number;
					}>(`${queryKey[0]}/${queryKey[1]}/calculate-costs`, {
						...payload,
						origin,
						destination,
					});
				}
			)
		);

		return response
			.map((res) => res.data)
			.reduce(
				(acc, data) => {
					acc.fuelCost += data.fuelCost;
					acc.duration += data.duration;
					acc.distance += data.distance;
					return acc;
				},
				{ fuelCost: 0, duration: 0, distance: 0 }
			);
	}

	async getPreviousLocation(flightId: UUID) {
		const response = await httpClient.get<{
			address?: string;
		}>(`${this.queryKey}/${flightId}/previous-location`);

		return response.data;
	}

	async changeStatus(payload: {
		flightId: UUID;
		status: FlightStatus;
		cancelNote?: string;
	}) {
		const response = await httpClient.patch<Flight>(
			`${this.queryKey}/${payload.flightId}/change-status`,
			{
				status: payload.status,
				cancelNote: payload.cancelNote,
			}
		);

		return response.data;
	}

	async bulkStatusChange(payload: {
		status: FlightStatus;
		flights: BaseEntity[];
	}) {
		await httpClient.post(`${this.queryKey}/change-status`, payload);

		return 1;
	}

	async generateCertificate(flightId: UUID) {
		const response = await httpClient.get<Blob>(
			`${this.queryKey}/${flightId}/certificate`
		);

		return response.data;
	}

	async generatePassengersList(flightId: UUID) {
		const response = await httpClient.get<Blob>(
			`${this.queryKey}/${flightId}/summary-card`
		);

		return response.data;
	}

	async generateSummaryCard(flightId: UUID) {
		const response = await httpClient.get<Blob>(
			`${this.queryKey}/${flightId}/passengers-list`
		);

		return response.data;
	}
}

export default new FlightApi();
