import { UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { PaymentMethod } from '@/configs/paymentOptions';
import EntityApi from './EntityApi';

export enum PaymentStatus {
	PENDING,
	SUCCESS,
	FAILURE,
}

export interface Payment {
	id: UUID;
	amount: number;
	createdAt: string;
	returnUrl: string;
	status: PaymentStatus;
	paymentMethod: PaymentMethod;
}

class PaymentApi extends EntityApi<Payment> {
	constructor() {
		super(ApiQueryKey.payment);
	}
}

export default new PaymentApi();
