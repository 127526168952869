import { Attachment, UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { ProductCategory } from './ProductCategoryApi';
import { ProductForm } from '@/pages/products/CreateProduct';
import { Voucher } from './VoucherApi';
import EntityApi from './EntityApi';
import httpClient from './httpClient';
import AppApi from './AppApi';

export enum ProductType {
	ACCESSORY,
	VOUCHER,
}

export interface Product {
	id: UUID;
	name: string;
	description: string;
	category: ProductCategory;
	price: number;
	type: ProductType;
	listOrder: number;
	voucher?: Voucher;
	image?: Attachment;
}

class ProductApi extends EntityApi<Product> {
	constructor() {
		super(ApiQueryKey.products);
		this.createWithImage = this.createWithImage.bind(this);
		this.updateWithImage = this.updateWithImage.bind(this);
	}

	async createWithImage(payload: ProductForm) {
		let image: Attachment | undefined = undefined;

		if (payload.image) image = await AppApi.createAttachment(payload.image);

		const response = await httpClient.post<Product>(this.queryKey, {
			...payload,
			image,
			category: { id: payload.category },
			voucher: payload.voucher ? { id: payload.voucher } : null,
		});

		return response.data;
	}

	async updateWithImage(payload: ProductForm) {
		let image: Attachment | undefined = undefined;

		if (payload.image) image = await AppApi.createAttachment(payload.image);

		const response = await httpClient.patch<Product>(
			`${this.queryKey}/${payload.id}`,
			{
				...payload,
				image,
				category: { id: payload.category },
				voucher: payload.voucher ? { id: payload.voucher } : null,
			}
		);

		return response.data;
	}
}

export default new ProductApi();
