import { User } from '@/types/users';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { Flight } from './FlightApi';
import { FlightPassengerStatus } from './FlightPassengerApi';
import { UserVoucher } from './UserVoucherApi';
import { BaseEntity } from './EntityApi';
import { UUID } from '@/types/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import httpClient from './httpClient';

export enum StatsType {
	user = 'user',
}

type StatsMap = {
	[StatsType.user]: UserStats;
};

export enum NotificationTypes {
	EMAIL,
	SMS,
}

export enum NotificationQueueStatus {
	PENDING,
	SENT,
	FAILED,
}

export enum Notifications {
	USER_CREATED,
	FLIGHT,
}

export interface NotificationQueue extends BaseEntity {
	receiver: User;
	subject?: string;
	content: string;
	attempts: number;
	status: NotificationQueueStatus;
	notificationType: NotificationTypes;
	notificationTemplate: Notifications;
	sentAt?: string;
}

export interface UserFlightStats {
	flight: Flight;
	status: FlightPassengerStatus;
	vouchers: UserVoucher[];
}

export interface UserNotificationStats {
	emails: NotificationQueue[];
	sms: NotificationQueue[];
}

export interface UserAmountStats {
	flights: Record<FlightPassengerStatus, number>;
	notifications: Record<'email' | 'sms', number>;
	invites: number;
}

export interface UserStats {
	flights: UserFlightStats[];
	notifications: UserNotificationStats;
	amounts: UserAmountStats;
}

class StatsApi {
	queryKey: ApiQueryKey;

	constructor() {
		this.queryKey = ApiQueryKey.stats;
	}

	async getStats<TStats extends StatsType>({
		queryKey,
	}: QueryFunctionContext<[ApiQueryKey, StatsType, UUID]>) {
		const response = await httpClient.get<StatsMap[TStats]>(
			`${queryKey[0]}/${queryKey[1]}/${queryKey[2]}`
		);

		return response.data;
	}
}

export default new StatsApi();
