import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { AppProviders } from '@/providers/AppProviders';
import 'react-18-image-lightbox/style.css';
import './assets/scss/theme.scss';

const App = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);
